/* You can add global styles to this file, and also import other style files */
@use "@fiscalteam/ngx-nitro-services/theme/default/nitro_component_default_theme";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

:root {
  // TO enable light-dark css function
  color-scheme: light dark;

}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
}


// primeng-workardoud for tab links with too much margin around the link making it hard to click on them
p-tabmenu ul.p-tabmenu-nav {
  > li {
    padding: 0;

    > a {
      padding: var(--p-tabs-tab-padding);
    }
  }
}
